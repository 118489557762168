import React from 'react';
import { v4 } from 'uuid';
import { check, tags } from '../../services/video.service';
import templateDefinition from '../../data/template-generator/templateDefinitionGeneric';
import { dataValidate } from '../../helpers/validator';
import Toast from '../../helpers/notification';
import {
  getScenesFromData,
  getSceneDuration
} from '../../data/template-generator/video-suggestions/_helpers';
import {
  libraryAssets,
  findMusicLibrary,
  libraryAssetsSafely
} from '../../services/assets.services';
import { clone, isUrl, isColor, isGradientType, isMood } from '../../helpers/javascript';
import bulkNormalize from '../useBulkNormalize';
import Presets from '../../data/template-generator/presets';
import Textaligns from '../../data/template-generator/textaligns';

import { adaptiveFromData } from './adaptativeFromData';
import { reduceDataScenes } from './reduceDataScenes';
import { getVideoDataFromAutoText } from './getVideoDataFromAutoText';
import { parseCcFromText } from './parseCcFromText';

const normalize = bulkNormalize();
const presets = Presets.sort(() => Math.random() - 0.5);
const textaligns = Textaligns.sort(() => Math.random() - 0.5);

const moodCollection = [
  'Angry',
  'Calm',
  'Bright',
  'Dark',
  'Christmas',
  'Sad',
  'Romantic',
  'Epic',
  'Inspirational',
  'Happy',
  'Funky',
  'Funny',
  'Dramatic'
];

export default function dataVideoParse({
  setGlobalError = (e) => {},
  setTotalVideos,
  setState,
  setIsLoading,
  setData,
  useToast = true
}) {
  const extractHashtag = (text) => {
    return text.split(' ').filter((v) => !(v.startsWith('/') || v.startsWith('{{')));
  };

  const extractResourceType = (text) => {
    return text.split(' ').find((v) => v.startsWith('/'));
  };

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const mongoParse = (_data) => {
    const copy = clone(_data);
    const data = clone(_data);

    for (const key in copy) {
      if (Object.hasOwnProperty.call(data, key)) {
        delete data[key];
        const _key = key.replace(/\.|\$/g, '-');
        data[_key] = copy[key];
      }
    }

    return data;
  };

  const parseText = async (data) => {
    const _data = clone(data);
    for (const key in _data) {
      if (
        Object.hasOwnProperty.call(_data, key) &&
        _data[key] &&
        (key.startsWith('Title ') || key.startsWith('Text '))
      ) {
        const element = _data[key]?.replace(/\n/gm, '<br>').replace(/"/gm, '“');
        _data[key] = element;
      }
    }

    return _data;
  };

  const ccFromText = (data) => {
    let _data = clone(data);

    for (let index = 0; index < data.length; index++) {
      const element = data[index].data;
      Object.keys(element).map((keyName) => {
        if (
          (keyName.match(/^Title /g) || keyName.match(/^Text /g)) &&
          element[keyName] &&
          element[keyName].split('~').length > 1
        ) {
          _data = parseCcFromText(clone(_data), element, keyName);
        }
      });
    }

    return _data;
  };

  const parseMediaFromKeywords = async (data) => {
    const _data = clone(data);
    // if (!_data.Music) _data.Music = 'random';
    if (!_data.TextPosition) _data.TextPosition = 'random';
    if (!_data.TextStyle) _data.TextStyle = 'random';

    if (_data.BackgroundGradient) _data.UseBG = true;
    else {
      _data.UseBG = false;
      delete _data.BackgroundGradient;
    }
    if (_data.VoiceOver) _data.UseVoice = true;
    else {
      delete _data.VoiceOver;
      _data.UseVoice = false;
    }
    if (_data.Music === '/original') {
      _data.Music = '/original';
      _data.UseMusic = false;
      _data.UseOriginalMusic = true;
    } else if (_data.Music === '') {
      delete _data.Music;
      _data.UseMusic = false;
      _data.UseOriginalMusic = false;
    } else {
      _data.UseMusic = true;
      _data.UseOriginalMusic = false;
    }

    for (const key in _data) {
      if (
        Object.hasOwnProperty.call(_data, key) &&
        key.startsWith('Media') &&
        !isUrl(_data[key]) &&
        _data[key] &&
        _data[key] !== ''
      ) {
        const element = _data[key];
        const videoShape = _data['Video Shape'] || '9:16';
        const finalMedia = await getSmartMediaFromKeyword(element, videoShape);
        if (finalMedia === element && !isUrl(element)) {
          _data[key] = { noFound: true, element, videoShape };
        } else _data[key] = finalMedia;
      }

      if (
        Object.hasOwnProperty.call(_data, key) &&
        key.startsWith('Music') &&
        !isUrl(_data[key]) &&
        !_data[key].includes('/original') &&
        _data[key] !== ''
      ) {
        const element = _data[key].includes('/music') ? _data[key] : `/music ${_data[key]}`;
        const finalMedia = await getSmartMediaFromKeyword(element);
        _data[key] = finalMedia;
      }

      if (Object.hasOwnProperty.call(_data, key) && key.startsWith('TextPosition')) {
        if (_data[key].toLowerCase().includes('random')) {
          const textposition = textaligns.shift();
          _data[key] = textposition.name;
          textaligns.push(textposition);
        }
      }

      if (Object.hasOwnProperty.call(_data, key) && key.startsWith('TextStyle')) {
        if (_data[key].toLowerCase().includes('random')) {
          const textstyle = presets.shift();
          _data[key] = textstyle.name;
          presets.push(textstyle);
        }
      }
    }

    return _data;
  };

  /**
   * It will serve as a memory layer cache to don't ask pexels for a media
   * if we has already request it, we are using the media text as a key here
   * plus the video shape.
   *
   * The value could be a promise (if the media have not being resolved by pexels yet)
   * Or the final object (if it was suscesfull)
   */
  const mediaFromKeyWordsHash = {};

  const getSmartMediaFromKeyword = async (element, videoShape = '9:16') => {
    if (element && !isUrl(element)) {
      const keywords = extractHashtag(element);
      const resourceType = extractResourceType(element)
        ? extractResourceType(element).replace('/', '')
        : 'image';
      if (keywords.length > 0) {
        let assetsFromMemoryCache = {};
        // if the value is in already in the cache layer it means
        // that we don't need to ask for the resource again.
        if (mediaFromKeyWordsHash[element + videoShape]) {
          if (typeof mediaFromKeyWordsHash[element + videoShape].then === 'function') {
            const promiseResult = await mediaFromKeyWordsHash[element + videoShape];
            mediaFromKeyWordsHash[element + videoShape] = promiseResult;
            assetsFromMemoryCache = mediaFromKeyWordsHash[element + videoShape].assets;
          } else {
            assetsFromMemoryCache = mediaFromKeyWordsHash[element + videoShape].assets;
          }
        } else {
          mediaFromKeyWordsHash[element + videoShape] =
            resourceType === 'music'
              ? findMusicLibrary({
                  mood: isMood(keywords[0].toLowerCase())?.name || moodCollection.shift(),
                  limit: 20
                })
              : libraryAssetsSafely({
                  query: keywords.join(' '),
                  resourceType,
                  page: 1,
                  limit: 20,
                  videoShape: videoShape
                });

          const promiseResult = await mediaFromKeyWordsHash[element + videoShape];
          mediaFromKeyWordsHash[element + videoShape] = promiseResult;
          assetsFromMemoryCache =
            Array.isArray(mediaFromKeyWordsHash[element + videoShape].assets) &&
            mediaFromKeyWordsHash[element + videoShape].assets.sort(() => Math.random() - 0.5);
        }
        const media = Array.isArray(assetsFromMemoryCache) && assetsFromMemoryCache.shift();
        if (media) {
          assetsFromMemoryCache.push(media);
          return media.secure_url;
        }
      } else {
        return element;
      }
    }
    return element;
  };

  // var dataset1 =  `/auto [sea, lion, beach, child] /video<br>- The most popular social networks are algorithm-based.<br><br>- Your top-performing posts from the past can tell you a lot.<br><br>- Finding out when your audience is online will be one of the easiest ways to reach more followers and get your social media content out there.<br><br>- The most popular children.`;
  // var dataset2 =  `/auto [sea, lion, beach]<br>- The most popular social networks are algorithm-based.<br><br>- Your top-performing posts from the past can tell you a lot.<br><br>- Finding out when your audience is online will be one of the easiest ways to reach more followers and get your social media content out there.`;
  // var dataset3 =  `/auto [sea, lion king, beach along]<br>- The most popular social networks are algorithm-based.<br><br>- Your top-performing posts from the past can tell you a lot.<br><br>- Finding out when your audience is online will be one of the easiest ways to reach more followers and get your social media content out there.`;

  /*
  const handleBackground = param => {
    const typeParam = param.split('(')[0].trim();
    const colorsParam = param.split('(')[1].split(')')[0].split(',');
    const animatedParam = param.split(')')[1].trim();
    const json = {};
    json.type = 'woxo-background';
    json.width = 1080;
    json.height = 1920;
    json.gradientType = (isGradientType(typeParam) && typeParam) || 'linear';
    json.colors = colorsParam.map(c => c.trim()).filter(c => isColor(c));
    // json.colors = ["rgb(255, 104, 248)", "rgb(44, 209, 252)"];
    if (animatedParam && animatedParam === 'animated') json.animation = true;
    // console.log('backgroud json', json);
    return json;
  };
*/

  const processAfterImport = async (_data, whereFrom, idxTemplate = 0) => {
    setTotalVideos(_data.length);
    try {
      // Before this we were calling all media from Pexels in a sync way
      // it means that if we had 1500 medias the process take it forever :(
      const promises = _data.map(async (videoData, index) => {
        const autoData = getVideoDataFromAutoText(videoData['Title 1'] || videoData['Text 1']);

        if (autoData) delete videoData['Title 1'] || videoData['Text 1'];
        let _videoData = autoData ? Object.assign(videoData, autoData) : videoData;
        _videoData = reduceDataScenes(_videoData);
        _videoData = mongoParse(_videoData);
        _videoData = await parseText(_videoData);
        _videoData = await parseMediaFromKeywords(_videoData);
        // console.log("post data", _videoData);
        return _videoData;
      });

      const videoData = await Promise.all(promises);
      let result = normalize(clone(adaptiveFromData(videoData)));
      result = ccFromText(clone(result));

      const { validData, errors, warnings } = dataValidate(result, whereFrom, idxTemplate);

      setData(Object.assign(validData, { errors, warnings }));
      if (useToast) {
        errors.forEach((element) => {
          Toast.warning(element.message, `Warning! Video #${element.index + 1} was not imported`);
        });
      }
      // console.log('errors', errors);
      setGlobalError(errors);
      setState('pulled');
      setIsLoading(false);
      return Object.assign(validData, { errors, warnings });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return [];
    }
  };
  // const hasError = globalError && Array.isArray(globalError) && globalError.length > 0;

  return {
    processAfterImport,
    adaptiveFromData,
    reduceDataScenes,
    getSmartMediaFromKeyword,
    getVideoDataFromAutoText,
    parseMediaFromKeywords,
    parseCcFromText
  };
}
