import { z } from 'zod';

const emailSchema = z.string().trim().email();
const usernameSchema = z.string().trim().min(3).max(40).regex(/^\S+$/);

export function validateEmail(value: string) {
  return emailSchema.safeParse(value).success;
}

export function validateUsername(value: string) {
  return usernameSchema.safeParse(value).success;
}
