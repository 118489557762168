import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Link, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/nextjs';

function ErrorAlert() {
  return (
    <Box role="alert" display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
      <img src="/notfound/oops.png" alt="Error image" width="40%" />
      <Typography variant="h6">
        Sorry, an error has ocurred.
        <Link href={window.location.href}> Try reloading the page.</Link>
      </Typography>
    </Box>
  );
}

export default function ErrorAlertBoundary({ children }: React.PropsWithChildren<{}>) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorAlert}
      onError={(error, info) => {
        Sentry.captureException(error, { extra: { componentStack: info.componentStack } });
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
