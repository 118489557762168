import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { gtmFireEvent } from '~/helpers/gtm';
import { shuffle } from '../helpers/shuffleFilled';
import buildVideos from '../helpers/buildVideos';
import { updateScenes } from '~/hooks/useScenesUpdate';
import { useScenesStore } from './scenesStore';
import { useStylesStore } from './stylesStore';
import { useProjectStore } from './projectStore';
import { useDownloadStore } from './downloadStore';
import { useCategoriesStore } from './categoriesStore';
import { useUploadStore } from './uploadStore';
import { presets } from '../data/presets';
import { _async } from '~/helpers/javascript';

export const useBulkVideoStore = create(
  devtools(
    subscribeWithSelector((set, get) => ({
      ...useScenesStore(set, get),
      ...useStylesStore(set, get),
      ...useProjectStore(set, get),
      ...useDownloadStore(set, get),
      ...useCategoriesStore(set, get),
      ...useUploadStore(set, get),

      //states
      loading: true,
      editlyVideoData: [],
      woxoVideoData: [],
      videoRef: [],

      // User related
      showSignUpmodalAction: () => {},
      user: null,
      plan: null,

      // Using for testing
      testingMode: false,
      hideVideoInfo: false,
      version: null,

      // Used to know if the videos are loading
      loadingVideos: false,
      videosLoading: [],

      //actions
      setupLoadingAction: (loading) => {
        set({ loading });
      },

      setupUserAction: (user) => {
        set({ user });
      },

      setupPlanAction: (plan) => {
        set({ plan });
      },

      setVersionAction: (version) => {
        set({ version });
      },

      setupShowSignUp: (showSignUp) => {
        set({ showSignUpmodalAction: showSignUp });
      },

      shuffleAction: () => {
        const styles = shuffle(get().contentVideoData.length, presets(get().activeCategory));

        let _contentVideoData = get().contentVideoData;
        _contentVideoData.map((d, i) => {
          d.font = styles[i]?.font;
          d.backgroundColor = styles[i]?.backgroundColor;
          d.textColor = styles[i]?.textColor;
          d.textStyle = styles[i]?.textStyle;
        });

        set({ contentVideoData: _contentVideoData });
        get().buildVideosAction();
        get().trackingAction({
          event: 'onButtonClick',
          category: 'bulk-creator',
          action: `shuffle-click`
        });
        get().addProjectVersionsAction();
      },

      buildVideosAction: async () => {
        const process = async () => {
          const _woxoVideoData = await buildVideos({
            artboardVideoData: get().artboardVideoData,
            woxoVideoData: get().woxoVideoData,
            textPosition: get().textPosition,
            videoShape: get().videoShape,
            fontSize: get().fontSize,
            music: get().music,
            textColors: get().textColors,
            contentVideoData: get().contentVideoData,
            version: get().version
          });
          const _editlyVideoData = [];
          updateScenes(
            _woxoVideoData,
            (videos) => {
              _editlyVideoData.push(videos);
            },
            { overrideID: true }
          );

          set({ editlyVideoData: _editlyVideoData, woxoVideoData: _woxoVideoData });
          get().videosLoadingAction();

          // NOTE: I know it is not cool :( but I'm just trying to help the QA team
          // to have more visibility into internal proccesses

          console.log(
            'ACTIONS -> BuildVideos -> editlyVideoData - woxoVideoData',
            _editlyVideoData,
            _woxoVideoData
          );
          if (typeof window !== 'undefined') {
            const event = new CustomEvent('woxo.actions.build-videos', {
              detail: { editlyVideoData: _editlyVideoData, woxoVideoData: _woxoVideoData }
            });
            window.dispatchEvent(event);
            // window.addEventListener('woxo.actions.build-videos', function (e) { console.log(e.detail) }, false);
          }
        };
        // TODO: Esto es un PARCHE para que no bloquee el hilo principal.
        _async(() => {
          process();
        });
      },

      setTestingModeAction: (testingMode) => set({ testingMode }),
      setHideVideoInfoAction: (hideVideoInfo) => set({ hideVideoInfo }),

      trackingAction: gtmFireEvent,

      videosLoadingAction: () => {
        let _videos = [];
        for (let i = 0; i < get().editlyVideoData[0]?.length; i++) {
          _videos.push(true);
        }

        set({ videosLoading: _videos });
      },

      setVideosLoadingAction: (idx) => {
        let _videos = get().videosLoading;
        if (_videos[idx]) _videos[idx] = false;

        set({ videosLoading: _videos });
        if (_videos.every((v) => v === false)) set({ loadingVideos: false });
      },

      restoreState: async (prevState) => {
        set({ loading: true });
        set({
          artboardVideoData: prevState.artboardVideoData,
          activeScene: prevState.activeScene,
          woxoVideoData: prevState.woxoVideoData,
          videoRef: prevState.videoRef,
          editlyVideoData: prevState.editlyVideoData,
          // Project
          project: prevState.project,
          projectStatus: prevState.projectStatus,
          projectName: prevState.projectName,
          projectCode: prevState.projectCode,
          projectVersions: prevState.projectVersions,
          activeProjectVersion: prevState.activeProjectVersion,
          undoActive: prevState.undoActive,
          redoActive: prevState.redoActive,
          undoApplied: prevState.undoApplied,
          // Style
          textPosition: prevState.textPosition,
          videoShape: prevState.videoShape,
          videoShapeUse: prevState.videoShapeUse,
          fontSize: prevState.fontSize,
          textColors: prevState.textColors,
          music: prevState.music,
          contentVideoData: prevState.contentVideoData,
          // Others
          testingMode: prevState.testingMode,
          hideVideoInfo: prevState.hideVideoInfo,
          version: prevState.version,
          numberVideos: prevState.numberVideos
        });
        await get().buildVideosAction();
        set({ loading: false });
      }
    }))
  )
);
